@import '@styles/mixins.style';

.list {
  display: flex;
  flex-direction: column;
  gap: var(--ra-spacing-6);
}

.card__loader {
  display: flex;
  flex-direction: column;
  gap: var(--ra-spacing-5);
  max-width: 100%;
  overflow: hidden;

  @include breakpoint(md) {
    display: grid;
    grid-template-columns: 3fr 5fr 4fr;
    padding: var(--ra-spacing-5);
    margin-block: var(--ra-spacing-3);
    background-color: var(--color-white);
    align-items: center;
  }

  &__images {
    :nth-child(1) {
      @include breakpoint(md) {
        transform: rotate(1.5deg);
      }
    }

    :nth-child(2) {
      display: none;
      @include breakpoint(md) {
        display: block;
        transform: rotate(-1.5deg);
      }
    }

    :nth-child(3) {
      display: none;
      @include breakpoint(md) {
        display: block;
        transform: rotate(1.5deg);
      }
    }
  }

  &__content {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 48px;

    &__header {
      margin-bottom: var(--ra-spacing-5);
    }
  }

  &__stepmap {
    display: none;
    @include breakpoint(md) {
      display: block;
    }
  }
}
