@import '@styles/mixins.style';

.ra-topbar-contact {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  justify-self: flex-end;

  &--mobile {
    @include breakpoint(md) {
      display: none;
    }
  }

  &--desktop {
    display: none;
    @include breakpoint(md) {
      display: block;
      button {
        padding-right: 0;
      }
    }
  }

  &__button {
    position: relative;
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--color-neutral-1);
    font-family: var(--ra-font-inter);
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  dialog {
    background-color: transparent;
    outline: none;
    border: none;
    z-index: 99999;
    position: absolute;
    right: 0 !important;
    margin-right: 0 !important;
    top: var(--ra-spacing-6);
    width: 350px;
    height: auto;
    pointer-events: none;
    padding: 0;

    p,
    h5 {
      padding: 0;
      margin: 0;
      font-size: var(--ra-font-size-14);
    }

    h5 {
      font-weight: var(--ra-font-weight-semibold);
      font-family: var(--ra-font-family-inter);
    }

    hr {
      margin-block: var(--ra-spacing-4);
      border: 0;
      background-color: var(--color-translucent-dark-2);
      height: 1px;
    }
  }

  dialog[open] {
    pointer-events: all;
  }
}
