@import '@styles/mixins.style';

.navbar-interaction {
  position: absolute;
  height: var(--settings-navbar-height);
  width: 100%;
  background-color: transparent;
  transition: background 500ms ease;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }

  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 24px;
    background-color: var(--color-bg-light);
    mask: url('/assets/icons/navbar-torn-edge.svg');
    mask-position: top left;
    mask-size: contain;
    mask-repeat: repeat-x;
    opacity: 0;
    transition: opacity 350ms ease, bottom 1s ease;
    transition-delay: 0ms, 350ms;
    pointer-events: none;
  }

  &--no-hero {
    height: var(--settings-navbar-height);
  }

  &.navbar-interaction--scrolling {
    background: var(--color-bg-light);
    width: 100%;
    filter: var(--ra-drop-shadow);

    &::before {
      display: none;
    }

    &::after {
      opacity: 1;
      transition: opacity 500ms ease, bottom 1s ease;
      transition-delay: 350ms, 100ms;
      transition-duration: 100ms 2s;
      bottom: -23px;
    }
  }
}
