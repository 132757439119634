@keyframes wheel-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ra-portal {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: var(--ra-spacing-4);
  }
}

.active {
  svg {
    animation: wheel-spin 25s forwards infinite;
  }
}
