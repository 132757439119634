@import '@styles/mixins.style';

.ra-topbar-destinations {
  display: none;
  @include breakpoint(md) {
    height: var(--settings-topbar-height);
    display: flex;
    align-items: center;
    gap: var(--ra-spacing-4);
    width: fit-content;

    button {
      margin-left: 0;
      padding-left: 0;
    }

    [data-name='dropdown-menu'] {
      position: fixed;
      left: 0;
      width: 100%;
      height: auto;
      min-height: 500px;
      z-index: 100;
      top: 0;
      opacity: 0;
      transition: all 350ms ease;
      pointer-events: none;
    }

    &__open {
      [data-name='dropdown-menu'] {
        top: var(--settings-topbar-height);
        opacity: 1;
        pointer-events: all;
        visibility: visible;
      }
    }
  }
}
