.ra-buildingblock-page {
  position: relative;
  &__back {
    position: absolute;
    top: var(--settings-navbar-height);
    left: 24px;
    z-index: 2;
  }
}

.add-to-cart {
  display: flex;
  justify-content: center;
  padding-block: var(--ra-spacing-6);
}
