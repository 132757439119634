@import '@styles/mixins.style';

.ra-navbar-mobile {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;

  // &--active {
  //   * {
  //     color: var(--navbar-text-color);
  //     span {
  //       color: var(--navbar-color-light);
  //     }
  //   }
  // }

  @include breakpoint(md) {
    display: none !important;
  }

  &__container {
    align-items: center;
    @include breakpoint(md) {
      pointer-events: none;
    }
  }
}
