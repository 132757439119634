@import '@styles/mixins.style';

.ra-product-sticky-footer {
  position: fixed;
  bottom: -500px;
  left: 0;
  width: 100vw;
  transition: bottom 0.35s ease;
  opacity: 0.99; // Need this for IOS fix
  z-index: 5;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: var(--ra-spacing-1);
    gap: var(--ra-spacing-4);

    h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: none;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: pre-line;
      max-width: 50%;
      @include breakpoint(sm) {
        display: -webkit-box;
      }
    }
    button {
      width: 100%;
      @include breakpoint(sm) {
        width: auto;
      }
    }
  }

  &--active {
    bottom: 0;
  }
}
