@import '@styles/mixins.style';

.ra-trustpilot-topbar {
  color: var(--color-neutral-1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--ra-spacing-2);

  @include breakpoint(md) {
    justify-content: center;
  }

  &:hover {
    text-decoration: none;
  }
}
