@import '@styles/mixins.style';

.nav-dropdown {
  z-index: 20;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: opacity 350ms ease;

  &:hover {
    visibility: visible;
    pointer-events: all;
    opacity: 1;
    cursor: initial;
  }

  &__backdrop {
    position: relative;
    content: '';
    left: 0;
    top: 0;
    background-color: var(--color-bg-light) !important;
    z-index: 99999 !important;
  }

  &__content {
    width: 100%;
    height: 100%;
    gap: var(--ra-spacing-5);
    max-width: 1100px;
    margin-inline: auto;
    padding-block: var(--ra-spacing-5);
    display: grid;
    grid-template-columns: repeat(var(--navbar-columns, 5), 1fr);

    @media only screen and (min-width: 1280px) {
      max-width: 1280px;
      margin-inline: auto;
    }

    @include breakpoint(xl) {
      max-width: 1512px;
    }

    &--no-padding {
      padding-top: 0;
    }

    // hr {
    //   border: none;
    //   border-bottom: 1px solid;
    //   border-color: var(--color-translucent-dark-1);
    // }

    &__columns {
      display: block;
    }

    &__list {
      list-style-type: none;
      padding-left: 0;
      display: block;
      margin-top: 0;

      &__item {
        padding-block: var(--ra-spacing-1);
        a {
          width: fit-content;
          display: flex;
          align-items: center;
          gap: var(--ra-spacing-4);

          svg {
            opacity: 0;
          }

          &:hover {
            text-decoration: none !important;
            span {
              color: var(--color-primary) !important;
            }
            svg {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.ra-navbar {
  &__menu-item {
    &:hover,
    &:focus-visible {
      [data-name='dropdown-menu'] {
        visibility: visible;
        pointer-events: inherit;
        opacity: 1;
      }
    }

    &:focus-visible {
      outline: none;
      border: none;

      > a {
        span {
          outline: 2px solid var(--color-primary);
          outline-offset: 4px;
          border-radius: 2px;
        }
      }
    }
  }
}
