@import '@styles/mixins.style';

.card {
  display: flex;
  flex-direction: column;
  gap: var(--ra-spacing-4);

  img {
    margin-bottom: var(--ra-spacing-5);
  }

  @include breakpoint(sm) {
    display: grid;
    grid-template-columns: 5fr 9fr;
    gap: var(--ra-spacing-6);
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;

    button,
    a {
      width: 100%;

      @include breakpoint(sm) {
        width: fit-content;
      }
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: var(--ra-spacing-3);
    margin-bottom: var(--ra-spacing-3);
    align-items: center;
  }

  &__title {
    width: fit-content;
    &:focus-within {
      outline: var(--ra-focus-outline) var(--color-primary);
      border-radius: 2px;
    }
  }

  &__index {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px var(--color-translucent-dark-2) dashed;
  }

  &__details {
    margin-bottom: var(--ra-spacing-5);
  }
}
