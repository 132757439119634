.blog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--ra-spacing-6);

  &__grid {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(1, 1fr);

    @media screen and (min-width: 620px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 1078px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
