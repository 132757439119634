@import '@styles/mixins.style';

.ra-footer-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: var(--ra-spacing-6);
  gap: var(--ra-spacing-6);

  @include breakpoint(md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-block-start: var(--ra-spacing-7);
  }

  &__portals {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--ra-spacing-6);

    @include breakpoint(sm) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: var(--ra-spacing-7);
    }
  }

  &__portal {
    width: 248px;
    height: 248px;

    &__richtext {
      a {
        color: var(--color-black) !important;
      }
      p {
        word-break: break-all;
      }
    }
  }
}
