@import '@styles/mixins.style';

.keyfact {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--ra-spacing-2);
  @include breakpoint(md) {
    flex-direction: row;
    gap: var(--ra-spacing-4);

    :first-child {
      flex: 0 0 132px;
    }
  }

  &--row {
    flex-direction: row;
    gap: var(--ra-spacing-1);

    @include breakpoint-max(sm) {
      margin-bottom: var(--ra-spacing-1);
    }

    :first-child {
      flex: 0 0 100px;
    }
  }
}
