@import '@styles/mixins.style';

.ra-topbar {
  height: var(--settings-topbar-height);
  width: 100%;
  background-color: var(--color-primary);
  z-index: 30;
  position: sticky;
  top: 0;

  &__content {
    width: 100%;
    height: var(--settings-topbar-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-neutral-1);

    @include breakpoint(md) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
