.ra-footer-newsletter {
  position: relative;
  background-color: var(--color-primary);
  padding-top: var(--ra-spacing-7);
  padding-bottom: var(--ra-spacing-6);
  padding-inline: var(--ra-spacing-2);
  overflow: hidden;

  a {
    color: var(--color-white) !important;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 24px;
    background-color: var(--color-bg-light);
    mask: url('/assets/icons/navbar-torn-edge.svg');
    mask-position: top left;
    mask-size: contain;
    mask-repeat: repeat-x;
    pointer-events: none;
  }

  &::before {
    top: -1px;
  }

  &::after {
    bottom: -1px;
    transform: rotate(180deg);
  }

  &__wrapper {
    max-width: 600px;
    margin-inline: auto;

    button {
      margin-top: var(--ra-spacing-6);
    }
  }
}
