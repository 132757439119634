@import 'mixins.style';

:root {
  /// -------  - Size  ------- ///
  --ra-font-size-80: 5rem; //=> 80px
  --ra-font-size-72: 4.5rem; //=> 72px
  --ra-font-size-64: 4rem; //=> 64px
  --ra-font-size-60: 3.75rem; //=> 60px
  --ra-font-size-54: 3.375rem; //=> 54px
  --ra-font-size-44: 2.75rem; //=> 44px
  --ra-font-size-40: 2.5rem; //=> 40px
  --ra-font-size-32: 2rem; //=> 32px
  --ra-font-size-28: 1.75rem; //=> 28px
  --ra-font-size-24: 1.5rem; //=> 24px
  --ra-font-size-20: 1.25rem; //=> 20px
  --ra-font-size-18: 1.125rem; //=> 18px
  --ra-font-size-16: 1rem; //=> 16px
  --ra-font-size-14: 0.875rem; //=> 14px
  --ra-font-size-12: 0.75rem; //=> 12px

  --ra-font-size-display-1: var(--ra-font-size-80);
  --ra-font-size-display-2: var(--ra-font-size-72);
  --ra-font-size-display-3: var(--ra-font-size-54);

  --ra-font-size-heading-1: var(--ra-font-size-44);
  --ra-font-size-heading-2: var(--ra-font-size-32);
  --ra-font-size-heading-3: var(--ra-font-size-24);
  --ra-font-size-heading-4: var(--ra-font-size-20);
  --ra-font-size-heading-5: var(--ra-font-size-18);
  --ra-heading-margin: 16px;

  --ra-font-size-body-xs: var(--ra-font-size-10);
  --ra-font-size-body-s: var(--ra-font-size-12);
  --ra-font-size-body-m: var(--ra-font-size-14);
  --ra-font-size-body-l: var(--ra-font-size-16);
  --ra-font-size-body-xl: var(--ra-font-size-18);
  --ra-font-size-forms: var(--ra-font-size-15);
  --ra-body-margin: 16px;

  /// -------  - Font weight  ------- ///
  --ra-font-weight-normal: 400;
  --ra-font-weight-semibold: 600;
  --ra-font-weight-bold: 700;
}

/// -------  - Responsive  ------- ///
///
///

// Phone
@include breakpoint(xs) {
  :root {
  }
}
// Tablet
@include breakpoint(sm) {
  :root {
    --ra-font-size-body-xs: var(--ra-font-size-12);
    --ra-font-size-body-s: var(--ra-font-size-14);
    --ra-font-size-body-m: var(--ra-font-size-16);
    --ra-font-size-body-l: var(--ra-font-size-18);
    --ra-font-size-body-xl: var(--ra-font-size-20);
    --ra-font-size-forms: var(--ra-font-size-16);
  }
}
// Laptop
@include breakpoint(md) {
  :root {
    --ra-font-size-heading-1: var(--ra-font-size-60);
    --ra-font-size-heading-2: var(--ra-font-size-40);
    --ra-font-size-heading-3: var(--ra-font-size-28);
    --ra-font-size-heading-4: var(--ra-font-size-24);
    --ra-font-size-heading-5: var(--ra-font-size-20);
  }
}
// Laptop HD
@include breakpoint(lg) {
  :root {
  }
}
// Laptop large
@include breakpoint(xl) {
  :root {
  }
}

body {
  /// -------  - Font family  ------- ///
  --ra-font-family-heading: var(--ra-font-heading);
  --ra-font-family-body: var(--ra-font-inter);
  font-family: var(--ra-font-family-body);
  font-weight: var(--ra-font-weight-normal);
  font-size: var(--ra-font-size-body-m);
  line-height: 160%;
  letter-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--ra-font-heading);
  font-weight: var(--ra-font-weight-normal);
  line-height: 120%;
  letter-spacing: 0;
  margin-block-start: 0;
  margin-block-end: var(--ra-heading-margin);
}

h1 {
  font-size: var(--ra-font-size-heading-1);
}

h2 {
  font-size: var(--ra-font-size-heading-2);
}

h3 {
  font-size: var(--ra-font-size-heading-3);
}

h4 {
  font-size: var(--ra-font-size-heading-4);
}

h5 {
  font-size: var(--ra-font-size-heading-5);
}

p {
  padding-top: 0;
  margin-bottom: var(--ra-body-margin);
}

ul {
  list-style-type: none;
  padding-left: 0;
}

a {
  color: var(--color-primary) !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus-visible {
    outline: 2px solid var(--color-primary);
    outline-offset: 5px;
    border-radius: 1%;
  }

  &:has(button) {
    &:hover {
      text-decoration: none;
    }
  }
}
