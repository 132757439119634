@import '@styles/mixins.style';

.row {
  display: flex;
  flex-direction: column;
  margin-block: var(--ra-spacing-4);

  @include breakpoint(sm) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 48px;
    margin-block: var(--ra-spacing-6);
    align-items: center;
  }

  &--reversed {
    flex-direction: column-reverse;

    @include breakpoint(md) {
      .image {
        justify-content: flex-end;
      }
    }
  }
}
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  @include breakpoint(md) {
    justify-content: flex-start;
  }
  img {
    max-width: 100%;
    height: auto;
    box-shadow: var(--ra-elevation-light);
  }

  &--left {
    img {
      transform: rotate(-1.5deg);
    }
  }

  &--right {
    img {
      transform: rotate(1.5deg);
    }
  }
}
