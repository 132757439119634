@import '@styles/mixins.style';

.ra-footer-copyright {
  padding-block: var(--ra-spacing-2);
  background-color: var(--color-white);

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-white);

    @include breakpoint(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: var(--ra-spacing-4);
    }

    &__links {
      display: flex;
      flex-direction: column;
      gap: var(--ra-spacing-1);
      @include breakpoint(md) {
        display: flex;
        flex-direction: row;
        gap: var(--ra-spacing-2);
      }
    }
  }
}
