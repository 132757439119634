.skip-link {
  position: fixed;
  top: 12px;
  left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  background-color: var(--color-white);
  z-index: 999;
  padding-inline: var(--ra-spacing-4);
  box-shadow: var(--ra-elevation-heavy);
  border-radius: 8px !important;
  opacity: 0;

  &:focus-visible {
    opacity: 1;
    outline: none;
  }
}
