@import '@styles/mixins.style';

.ra-footer-partners {
  background-color: var(--color-white);
  border-top: 1px solid var(--color-translucent-dark-1);

  &__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--ra-spacing-4);
    margin-block: var(--ra-spacing-4);

    @include breakpoint(md) {
      margin-block: var(--ra-spacing-4);
    }

    img {
      height: 100px;
      width: auto;
      object-fit: contain;
    }
  }
}
