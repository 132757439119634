.wrapper {
  position: absolute;
  top: var(--ra-spacing-6);
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  pointer-events: none;
  opacity: 0.1;
  top: 0;
}

.visual {
  pointer-events: none;
  z-index: -1;
  * {
    max-width: 100% !important;
    overflow: hidden !important;
  }

  &--left {
    &-negative {
      left: -240px;
    }

    &-default {
      left: 0;
    }

    &-positive {
      left: 240px;
    }
  }

  &--rotate {
    &-left {
      transform: rotate(-12deg);
    }

    &-right {
      transform: rotate(12deg);
    }
  }
}
