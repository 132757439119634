.logo {
  display: flex;
  z-index: 999;
  gap: var(--ra-spacing-4);

  &__default-wrapper {
    display: flex;
    gap: 6px;
    align-items: center;
    min-width: 24px;
    > span {
      line-height: 1.25rem;
      transform: translateY(4px);
    }
    svg {
      transform: scale(0.85);
    }
  }

  &__titles {
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__default-subtitle {
      margin-left: calc(24px + 7px);
      transform: translateY(-6px);

      &--family {
        margin-left: calc(34px + 7px);
      }
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.erlebe-family {
  display: flex;
  flex-direction: row;
  gap: 6px;
}
