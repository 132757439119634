.card {
  display: flex;
  flex-direction: column;

  // Paper el
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  img {
    width: 100%;
  }

  &__content {
    margin-top: var(--ra-spacing-5);
    padding: var(--ra-spacing-2);
  }

  &__button {
    margin-top: auto;
    margin-bottom: 0;
    padding: var(--ra-spacing-2);
  }
}
