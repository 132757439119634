.section {
  margin-bottom: 40px;
  &__video {
    padding: 32px 0;
    iframe {
      width: 100%;
    }
  }
  &__slider-wrp {
    padding: 32px 0;
  }
  &__slider {
    width: 75%;
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.3));
    margin: 0 auto;
    @media only screen and (min-width: 425px) {
      height: 300px !important;
    }
    @media only screen and (min-width: 600px) {
      height: 500px !important;
      width: 83%;
    }
    &--rotate {
      transform: rotate(3deg);
    }
  }
  &__form {
    margin-bottom: 70px;
  }
  &__content {
    line-height: 2.2rem;
  }
}
.section__video >>> iframe {
  width: 100%;
}
.section__slider >>> .previous {
  left: -30px !important;
}
.section__slider >>> .next {
  right: -30px !important;
}

.section__slider.slider__slider-rotate >>> .previous {
  top: 57.5%;
}
.section__slider.slider__slider-rotate >>> .next {
  top: 50%;
}

@media only screen and (min-width: 600px) {
  .section__slider >>> .previous {
    left: -55px !important;
  }
}
@media only screen and (min-width: 600px) {
  .section__slider >>> .next {
    right: -55px !important;
  }
}
