.ra-accordion {
  &__header {
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--ra-spacing-2);

    > svg {
      transform: rotate(0deg);
      transition: transform 0.25s ease;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus-visible {
      outline: var(--ra-focus-outline);
      border-radius: var(--ra-border-radius-xl);
    }
  }

  &__body {
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition:
      height 0.25s ease-in-out,
      opacity 0.15s 0.1s,
      visibility 0s 0.25s;
  }

  &--rich-content {
    background-color: var(--color-white);
    border-radius: var(--ra-border-radius-xl);
    box-shadow: var(--ra-elevation-light);
    padding: 0;
    margin-block: var(--ra-spacing-2);
    transition:
      box-shadow 200ms ease-in-out,
      margin 200ms ease-in-out;

    .ra-accordion {
      &__header {
        display: grid;
        grid-template-columns: 1fr 24px;
        color: var(--color-primary);
        width: 100%;
        padding: var(--ra-spacing-4);

        > svg {
          outline: 1px solid var(--color-translucent-dark-2);
          border-radius: 50%;
        }
      }
      &__body {
        margin-inline: var(--ra-spacing-4);
        padding-left: var(--ra-spacing-2);
        p {
          margin-top: 0;
        }
      }
    }
  }

  &--read-more {
    .ra-accordion__header {
      width: fit-content;
      gap: var(--ra-spacing-2);
    }
  }

  &--mobile-nav-subitem {
    button {
      display: flex;
      position: relative;
      padding-left: 38px;
      margin-block: 4px;

      &::before {
        color: var(--color-body-light);
        content: '↳';
        position: absolute;
        left: 12px;
        margin-right: var(--ra-spacing-2);
      }

      a {
        &::before {
          content: none;
        }
      }
    }
  }

  &--initial-run {
    .ra-accordion__body {
      visibility: visible;
      transition: visibility 0s;
    }
  }

  &--open {
    .ra-accordion__body {
      visibility: visible;
      opacity: 1;
      transition:
        height 0.25s ease-in-out,
        opacity 0.25s,
        visibility 0s;
    }

    .ra-accordion__header > svg {
      transform: rotate(180deg);
    }

    &.ra-accordion--rich-content {
      box-shadow: var(--ra-elevation-heavy);
      margin-block: var(--ra-spacing-4);
      padding-bottom: var(--ra-spacing-2);
      &:first-child {
        margin-top: var(--ra-spacing-2);
      }
      &:last-child,
      img {
        margin-bottom: var(--ra-spacing-2);
      }

      .ra-accordion__header > svg {
        transform: rotate(135deg);
      }
    }

    &.ra-accordion--mobile-nav {
      a {
        color: var(--color-body-light) !important;
        text-decoration: none;
        span {
          color: var(--color-body-light) !important;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
