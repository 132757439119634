@import 'mixins.style';

/// Site-specific styles are loaded from theme settings
///
/// @see src/app/(home)/layout.tsx

:root {
  /// -------  Colors - Brand  ------- ///
  --color-brand-300: #b32e37;
  --color-brand-500: #c86168;

  /// -------  Colors - Neutrals  ------- ///
  --color-white: #fff;
  --color-light: #fff;
  --color-neutral-1: #fffdf9;
  --color-neutral-2: #252d38;
  --color-neutral-3: #f7f1e4;
  --color-neutral-4: #faf6ed;
  --color-sand-light: var(--color-neutral-4);
  --color-sand-dark: var(--color-neutral-3);
  --color-family: #3287ad;

  // Translucency
  --color-translucent-light: 0.24;
  --color-translucent-heavy: 0.56;

  /// -------  Colors - Translucent  ------- ///
  --color-translucent-light-1: rgba(
    255,
    255,
    255,
    var(--color-translucent-light)
  );
  --color-translucent-light-2: rgba(
    255,
    255,
    255,
    var(--color-translucent-heavy)
  );
  --color-translucent-dark-1: rgba(37, 45, 56, var(--color-translucent-light));
  --color-translucent-dark-2: rgba(37, 45, 56, var(--color-translucent-heavy));

  --color-bg-riksja-light: #faf6ed;
  --color-bg-riksja-dark: #f7f1e4;
  --color-bg-erlebe-light: #f7f8f6;
  --color-bg-erlebe-dark: #eff4ee;
  --color-body: var(--color-neutral-2);
  --color-body-light: var(--color-neutral-1);
  --color-dark: var(--color-neutral-2);
  --color-body-dark: var(--color-neutral-2);
  --color-body-primary: var(--color-primary);
  --color-body-family: var(--color-family);
  --color-body-off-black: #3e4550;
  --color-erlebe: #4fb585;
}

html {
  color: var(--color-body);
}
