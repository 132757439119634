.ra-mobile-menu {
  padding-block: var(--ra-spacing-6);

  &__close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--ra-spacing-2);
    margin-right: var(--ra-spacing-2);
    button {
      outline: 1px solid var(--color-white);
      outline-offset: var(--ra-spacing-2);
    }
  }

  &__item {
    padding-block: var(--ra-spacing-4);
  }

  &__accordion {
    padding-block: var(--ra-spacing-4);

    &-content {
      display: flex;
      flex-direction: column;
      padding-top: var(--ra-spacing-2);

      a {
        padding-block: var(--ra-spacing-2);
        * {
          font-size: var(--ra-font-size-body-l) !important;
        }
        &::before {
          color: var(--color-body-light);
          content: '↳';
          position: relative;
          font-size: var(--ra-font-size-body-l);
          margin-right: var(--ra-spacing-3);
          padding-left: var(--ra-spacing-3);
        }
      }
    }
    &-subitem {
      display: flex;
      flex-direction: column;
      padding-block: var(--ra-spacing-1);
      a {
        margin-left: var(--ra-spacing-6);
        &::before {
          content: none;
        }
        &:last-child {
          margin-bottom: var(--ra-spacing-4);
        }
      }
    }
  }
}
