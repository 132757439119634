.ra-button {
  $root: &;

  --button-color: var(--ra-button-color, var(--color-primary));
  --button-text-color: var(--ra-button-text-color, var(--color-neutral-1));
  --button-width: var(--ra-button-width, unset);
  --icon-button-size: 24px;

  position: relative;
  width: var(--button-width);
  padding: var(--ra-spacing-4);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--ra-spacing-2);

  outline: none;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border-radius: var(--ra-border-radius-xl);
  border: 2px solid transparent;

  font-family: var(--ra-font-inter);
  font-size: var(--ra-font-size-16);
  font-weight: var(--ra-font-weight-semibold);

  transition: transform 0.2s ease;
  z-index: 1;

  &--small {
    padding-block: var(--ra-spacing-3);
    font-size: var(--ra-font-size-14);
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    margin: -3px;
    border-radius: var(--ra-border-radius-xl);
    opacity: 0;
    pointer-events: none;
    z-index: 1;
  }

  &:disabled {
    opacity: var(--color-translucent-heavy);
    pointer-events: none;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
    gap: var(--ra-spacing-2);
    padding: var(--ra-spacing-4);
  }

  &--full-width {
    --ra-button-width: 100%;
  }

  &--primary {
    &:focus-visible {
      &::before {
        position: absolute;
        content: '';
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        border: 2px solid var(--color-neutral-1);
        border-radius: calc(var(--ra-border-radius-xl) - 3px);
      }
    }

    &:hover {
      transform: scale(0.975);
      z-index: 1;
      background-color: var(--color-primary-dark);
      cursor: pointer;
    }
  }

  &:active {
    transition: transform 50ms;
    transform: scale(0.95);
  }

  &--secondary-dark {
    background-color: transparent;
    border: 2px solid var(--color-primary);
    color: var(--color-neutral-2);

    &:not(:disabled) {
      &:hover {
        color: var(--color-primary);
        cursor: pointer;
      }

      &:focus-visible {
        color: var(--color-primary);
      }
    }
  }

  &--secondary-light {
    background-color: transparent;
    border: 2px solid var(--color-neutral-1);
    color: var(--color-neutral-1);
    transition: 0.25s ease;

    &:not(:disabled) {
      &:hover {
        border-color: var(--color-primary);
        color: var(--color-primary);
      }

      &:focus-visible {
        border-color: var(--color-primary);
      }
    }
  }

  &--secondary-hero {
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(18px);
    border: 2px solid var(--color-neutral-1);

    &:not(:disabled) {
      &:hover {
        border-color: var(--color-primary);
        color: var(--color-primary);
      }

      &:focus-visible {
        border-color: var(--color-primary);
      }
    }
  }

  &--secondary-footer {
    background-color: transparent;
    border: 2px solid var(--color-neutral-1);
    color: var(--color-neutral-1);
    transition: 0.25s ease;

    &:not(:disabled) {
      &:hover {
        border-color: var(--color-white);
        color: var(--color-primary);
        background-color: var(--color-white);
      }

      &:focus-visible {
        border-color: var(--color-white);
        color: var(--color-primary);
        background-color: var(--color-white);
      }
    }
  }

  &--text-primary {
    background-color: transparent;
    padding: var(--ra-spacing-1);
    color: var(--color-primary);

    &:focus-visible {
      border: 2px solid var(--color-primary);
    }

    &:hover {
      color: var(--color-primary-dark);
    }
  }

  &--text-light {
    background-color: transparent;
    padding: var(--ra-spacing-1);
    color: var(--color-neutral-1);

    &:focus-visible {
      border: 2px solid var(--color-neutral-1);
    }
  }

  &--text-dark {
    background-color: transparent;
    padding: var(--ra-spacing-1);
    color: var(--color-neutral-2);

    &:focus-visible {
      border: 2px solid var(--color-neutral-2);
    }
  }

  &--icon {
    background: transparent;
    position: relative;
    padding: 0;
    width: var(--icon-button-size) !important;
    height: var(--icon-button-size) !important;
    min-width: var(--icon-button-size) !important;
    min-height: var(--icon-button-size) !important;
    max-width: var(--icon-button-size) !important;
    max-height: var(--icon-button-size) !important;
    border-radius: var(--icon-button-size);
    border: 0 !important;
    color: var(--ra-button-color);

    &:not(:disabled) {
      &:hover {
        background-color: var(--color-primary-800);
      }
      &:focus-visible {
        outline: 2px solid var(--color-primary);
      }
    }
  }

  &--travelplan {
    padding-block: var(--ra-spacing-3);
  }

  &__link {
    display: block;
    width: fit-content;

    &--fullwidth {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }

  &--loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    padding-block: var(--ra-spacing-1);
    padding-inline: var(--ra-spacing-4);
  }
}

.ra-tooltip {
  position: relative;

  .ra-button__tooltip {
    opacity: 0;
    position: absolute;
    left: -48px;
    transform: scale(0);
    top: -32px;
    text-align: center;
    background-color: var(--color-primary);
    border-radius: 8px;
    padding-inline: var(--ra-spacing-2);
    padding-block: 0;
    min-width: max-content;
    transition: all 0.15s ease-in-out;
  }

  &:hover,
  button:focus-visible {
    .ra-button__tooltip {
      opacity: 1;
      transform: scale(1);
    }
  }
}
