@import '@styles/mixins.style';

.columns {
  &__grid {
    display: flex;
    flex-direction: column;
    max-width: 100%;

    &--reverse {
      flex-direction: column-reverse;
    }

    @include breakpoint(md) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: var(--ra-spacing-5);
      align-items: flex-start;
    }

    @include breakpoint(lg) {
      gap: var(--ra-spacing-6);
    }
  }

  &__ctas {
    display: flex;
    flex-direction: column;
    gap: var(--ra-spacing-4);
    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    gap: var(--ra-spacing-5);

    &--negative-margin {
      @include breakpoint(md) {
        position: relative;
        align-self: flex-start;
        display: flex;
        flex-direction: column;

        &__container {
          display: flex;
          flex-direction: column;
          gap: var(--ra-spacing-5);
          margin-top: -150px;
        }
      }
    }

    &--hide-mobile {
      display: none;
      @include breakpoint(md) {
        display: block;
      }
    }

    &--hide-tablet {
      display: none;
      @include breakpoint(lg) {
        display: block;
      }
    }

    &--polaroid {
      @include breakpoint(lg) {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  iframe {
    max-width: 100%;
  }
}

.acf-column-grid-item {
  &__image {
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__content {
    img {
      max-width: 100% !important;
      box-shadow: var(--ra-elevation-light);
    }
  }

  &__icons {
    width: 100%;
  }
}
