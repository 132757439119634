@import './breakpoints.style';
@import './colors.style';
@import './mixins.style';
@import './typography.style';
@import './layout.style';

html,
body {
  position: relative;
  min-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  width: 100%;

  // :has(dialog[open]) {
  //   overflow: hidden !important;
  // }

  * {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
}

main {
  overflow-x: hidden;
  &:has([data-component='hero']),
  &:has([data-component='overview_filter']) {
    margin-top: -80px;
  }
}
