@import '@styles/mixins.style';

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include breakpoint(md) {
    align-items: flex-start;
  }

  &__image {
    margin-bottom: var(--ra-spacing-4);
    display: flex;
    justify-content: center;
    max-height: 100px;

    img {
      width: 100px;
      object-fit: contain;
    }
    @include breakpoint(md) {
      justify-content: flex-start;
    }
  }

  h3 {
    text-align: center;
    margin-bottom: var(--ra-spacing-2);
    @include breakpoint(md) {
      text-align: left;
    }
  }

  p {
    margin-top: 0;
    text-align: center !important;
    @include breakpoint(md) {
      text-align: left !important;
    }
  }

  &__content {
    height: auto;
  }

  &__button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
