@import '@styles/mixins.style';
@import '../RaNavbarDropdown/ra-navbar-dropdown.module.scss';

.ra-navbar {
  z-index: 25;
  position: sticky;
  top: var(--settings-topbar-height);
  height: var(--settings-navbar-height);

  &__desktop {
    display: none;
    @include breakpoint(md) {
      display: inline;
    }
  }

  &__mobile {
    position: relative;
    @include breakpoint(md) {
      display: none;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__menus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
    margin-left: 24px;
  }

  &__menu-item {
    width: 100%;
    display: flex;
    align-items: center;
    width: auto;
    height: 100%;

    &:last-child {
      .ra-navbar__menu-title {
        margin-right: var(--ra-spacing-1);
      }
    }
  }

  &__menu-title {
    color: var(--navbar-color-text);
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    height: var(--settings-navbar-height);
    width: fit-content;
    margin-inline: var(--ra-spacing-5);
    text-decoration: none !important;

    span {
      display: flex;
      align-items: center;
    }

    &::before {
      position: absolute;
      content: '';
      bottom: var(--ra-spacing-4);
      left: 0;
      height: 1px;
      width: 0;
      background-color: var(--navbar-color-text);
      transition: all 350ms ease;
    }

    svg {
      color: var(--navbar-color-text);
      margin-left: 12px;
      transform: rotate(0deg);
      transition: all 350ms ease;
    }
  }

  &__menu-item {
    &:focus-visible {
      > div {
        > span {
          outline: var(--ra-focus-outline) var(--color-primary);
          outline-offset: 4px;
          border-radius: 2px;
        }
      }
    }
    &:hover {
      &a {
        cursor: pointer;
      }
      .ra-navbar__menu-title {
        &::before {
          width: 100%;
        }

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__menu-title {
    &:focus-visible {
      &::before {
        width: 100%;
      }
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
