.acf-page {
  &--no-hero {
    height: 0px;
  }

  &-no-padding {
    padding-block: 0;
  }
}

.section {
  width: 100%;
  position: relative;
  padding-block: 40px;
  background-color: var(--color-bg-light);

  &--dark {
    background-color: var(--color-bg-dark);
  }

  &--remove-pt {
    padding-top: 0;
  }

  &--remove-pb {
    padding-bottom: 0;
  }

  &[data-component='columns'] {
    z-index: 2;
    padding-block: 0 !important;
  }

  &[data-component='hero'],
  &[data-component='navigation'],
  &[data-component='icon_block'],
  &[data-component='overview_filter'] {
    padding-block: 0;
  }

  &[data-component='icon_block'] {
    margin-top: -36px;
  }

  &[data-component='overview_filter'] {
    z-index: 6;
  }

  &[data-component='navigation'] {
    z-index: 10;
  }

  &[data-component='hero'] {
    overflow: hidden;
  }

  &[data-component='card_slider'] {
    overflow: hidden;
  }

  &[data-component='expansionpanel'] {
    overflow: hidden;
    padding-block: 0;
  }

  &[data-component='travelplan'] {
    padding-top: 0;
  }

  &[data-component='breadcrumbs'] {
    padding: 0;
  }

  &[data-component='introduction'] {
    padding-top: 0;
    margin-top: -100px;
  }

  &[data-component='sub_introduction'] {
    padding-block: 0;
    padding-block-start: var(--ra-spacing-4);
  }
}
