.ra-skeleton-loader {
  display: block;
  animation: skeleton-loading 1s linear infinite alternate;
  max-width: 85vw !important;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
