@import '@styles/mixins.style';

.ra-keyfacts {
  background-color: var(--color-bg-dark);

  &__tab-panel {
    display: flex;
    flex-direction: column;
    gap: var(--ra-spacing-6);
    padding-block: var(--ra-spacing-4);
    max-width: 1200px;
    margin-inline: auto;

    @include breakpoint(md) {
      display: grid;
      grid-template-columns: 1fr 400px;
      padding-block: var(--ra-spacing-6);
      width: 100%;
    }
  }

  &__stepmap {
    width: fit-content;
    min-height: 225px;
    @include breakpoint(sm) {
      min-height: 500px;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--ra-spacing-6);

    button {
      z-index: 0;
    }
  }

  &__image {
    display: none;
    @include breakpoint(md) {
      display: inline;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: var(--ra-spacing-4);
    flex-wrap: wrap;
    @include breakpoint(md) {
      gap: var(--ra-spacing-5);
    }
  }
}
