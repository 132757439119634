.ra-slider {
  max-width: 100%;
  position: relative;
  overflow: hidden;

  &__placeholder {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: calc(100% - (2 * 16px));
    height: 300px;
    margin: 16px;
  }

  &__controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin: 0;
  }

  &__btn {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--color-primary);
    border: none;
    font-size: var(--ra-font-size-body-m);
    font-weight: bold;
    line-height: 1;
    vertical-align: middle;
    color: white;

    cursor: pointer;

    transition: opacity 300ms, transform 200ms;

    &:hover:not(:disabled) {
      transform: scale(1.03);
    }

    &:active:not(:disabled) {
      transform: scale(0.97);
    }

    &[disabled='true'],
    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }

    & + & {
      margin-left: 16px;
    }
  }
}
