@import '@styles/mixins.style';

.card {
  display: flex;
  flex-direction: column;
  gap: var(--ra-spacing-4);

  @include breakpoint(sm) {
    display: grid;
    grid-template-columns: 500px 1fr;
    gap: var(--ra-spacing-6);
  }

  &__header {
    position: relative;
    display: grid;
    grid-template-columns: 36px 1fr;
    gap: var(--ra-spacing-3);
    margin-bottom: var(--ra-spacing-3);

    span {
      display: flex;
      align-items: center;
    }

    :first-child {
      justify-content: center;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      border: 1px dotted var(--color-translucent-dark-2);
    }
  }
  &__content {
    display: flex;
    flex-direction: column;

    iframe {
      max-width: 100% !important;
    }
  }

  &__add-to-cart {
    margin-top: 24px;
    button {
      width: fit-content;
    }
  }
}
