@import '@styles/mixins.style';

.hero {
  --acf-hero-height: 600px;
  --acf-hero-max-width: 100vw;

  @include breakpoint-max(sm) {
    --acf-hero-height: 450px;
  }

  position: relative;
  max-width: 100%;
  min-height: var(--acf-hero-height);
  overflow: hidden;
  z-index: 1;

  &--whitespacing {
    h1 {
      width: 100%;
      @include breakpoint(sm) {
        width: 60%;
      }

      @include breakpoint(md) {
        width: 50%;
      }
    }
  }

  &--torn-edge {
    position: relative;
    width: 100%;
    &::after {
      position: absolute;
      content: '';
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 24px;
      background-color: var(--color-bg-light);
      mask: url('/assets/icons/torn-edge.svg');
      mask-position: top left;
      mask-size: contain;
      mask-repeat: repeat-x;
      transform: scaleX(-1) rotate(180deg);
    }
  }

  &__background {
    position: absolute;
    inset: 0;
    height: var(--acf-hero-height);
    z-index: -1;
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(180.12deg, transparent 0.35%, #000 264%);
    }

    img,
    source {
      max-width: 100%;
      height: var(--acf-hero-height);
      margin: auto;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    gap: var(--ra-spacing-2);
    top: var(--ra-spacing-4);
    box-sizing: border-box;
    max-width: var(--acf-hero-max-width);
    min-height: var(--acf-hero-height);
    padding: 0;
    color: var(--color-neutral-1);

    @include breakpoint(md) {
      justify-content: flex-end;
      padding-bottom: 100px;
    }
  }

  &__buttons {
    display: flex;
    gap: 1rem;
    flex-flow: column;

    width: 100%;
    margin-top: var(--ra-spacing-5);
    a,
    button {
      width: 100%;
    }

    @include breakpoint(sm) {
      flex-flow: row;
      a,
      button {
        width: unset;
      }
    }
  }

  &__industry-icons {
    display: none;
    @include breakpoint(sm) {
      display: inline;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--color-white);
      border-radius: 24px 0px 0px 24px;

      ul {
        margin: var(--ra-spacing-2);
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
