@import '@styles/mixins.style';

.card {
  width: 100%;

  &__content {
    padding: var(--ra-spacing-4);
    gap: var(--ra-spacing-5);
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
      justify-content: space-between;
      gap: var(--ra-spacing-6);

      &:has(button) {
        span {
          max-width: 50%;
        }
      }
    }
  }
}
