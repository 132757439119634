@import '@styles/mixins.style';

.ra-grid {
  display: grid;
  grid-template-columns: repeat(var(--ra-grid-columns), 1fr);
  gap: 12px;

  @include breakpoint(md) {
    gap: var(--ra-grid-gap);
  }
}
