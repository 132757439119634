.torn-edge {
  width: 100%;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.75));
  z-index: -1;

  &::after {
    position: absolute;
    content: '';
    bottom: -23px;
    left: 0;
    width: 100%;
    height: 24px;
    background-color: var(--torn-edge-color);
    mask: url('/assets/icons/navbar-torn-edge.svg');
    mask-position: top left;
    mask-size: contain;
    mask-repeat: repeat-x;
  }
}
