@import '@styles/mixins.style';

.ra-usp {
  display: flex;
  align-items: center;
  gap: var(--ra-spacing-4);
  margin-block: var(--ra-spacing-1);

  @include breakpoint(md) {
    margin-block: var(--ra-spacing-4);
  }
}
