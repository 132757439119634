@import '@styles/mixins.style';

.ra-notification-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  padding-inline: 32px;
  padding-bottom: 12px;
  @include breakpoint(sm) {
    flex-direction: row;
    padding-inline: 48px;
    padding-bottom: 0;
  }
}
