@import '@styles/mixins.style';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--ra-spacing-5);

  @include breakpoint(sm) {
    display: grid;
    grid-template-columns: 300px 1fr;
  }

  &:not(:first-child) {
    margin-top: var(--ra-spacing-5);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-translucent-dark-1);
    padding-bottom: var(--ra-spacing-5);
  }

  &__image {
    img {
      width: 100%;
    }
  }

  &__content {
    @include breakpoint(md) {
      margin-right: var(--ra-spacing-5);
    }

    &__add-to-travelplan {
      margin-top: var(--ra-spacing-5);
    }
  }

  &__keyfacts {
    margin-bottom: var(--ra-spacing-5);
    padding-right: var(--ra-spacing-5);
  }

  &__repeater {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: var(--ra-spacing-5);
  }

  &__remove {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    gap: var(--ra-spacing-2);
    @include breakpoint(md) {
      top: 0;
      right: 0;
    }

    &__item {
      padding: var(--ra-spacing-1);
      background-color: var(--color-white);
      border-radius: 50%;
    }
  }

  &__controls {
    display: flex;
    flex-direction: column;
    gap: var(--ra-spacing-1);
    justify-content: flex-end;
    margin-block: var(--ra-spacing-2);
    float: right;
    @include breakpoint(md) {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &__undo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--ra-spacing-4);
    &:not(:first-child) {
      margin-top: var(--ra-spacing-5);
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-translucent-dark-1);
      padding-bottom: var(--ra-spacing-5);
    }
  }
}
