@import '@styles/mixins.style';

.grid {
  margin-block: var(--ra-spacing-6);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--ra-spacing-3);

  @include breakpoint(sm) {
    grid-template-columns: repeat(4, 1fr);
    gap: var(--ra-spacing-5);
  }

  &--bb {
    @include breakpoint(sm) {
      min-height: 0;
      height: 500px;
    }

    @include breakpoint(xl) {
      height: auto;
    }
  }

  &__item {
    display: none;
    position: relative;
    overflow: hidden;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      display: inline;
    }
    @include breakpoint(sm) {
      display: inline;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus-visible {
      outline: var(--ra-focus-outline) var(--color-primary);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--first {
      grid-column: 1 / 3;
      grid-row: 1 / 3;

      img {
        height: 100%;
      }
    }
    &--see-more {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-translucent-dark-2);
      display: flex;
      justify-content: center;
      align-items: center;

      &__chip {
        background-color: var(--color-white);
        padding-block: var(--ra-spacing-2);
        padding-inline: var(--ra-spacing-4);
        border-radius: 63px;
        outline: none;
        border: none;
        cursor: pointer;
        &:hover,
        &:focus-visible {
          background-color: var(--color-primary);
          span {
            color: var(--color-white) !important;
          }
        }
      }
    }
  }
}

.grid {
  &--variant {
    @include breakpoint(sm) {
      &-2,
      &-4 {
        grid-template-columns: repeat(2, 1fr);
        .grid__item--first {
          grid-column: 1;
          grid-row: 1;
        }
      }

      &-3 {
        grid-template-columns: repeat(3, 1fr);
        .grid__item--first {
          grid-column: 1 / 3;
          grid-row: 1 / 3;
        }
      }
    }
  }
}

.carousel {
  &__slide {
    height: auto;
  }
}

.ra-accommodation-gallery-modal {
  display: flex;
  flex-direction: column;
  padding-top: var(--ra-spacing-2);
  padding-bottom: var(--ra-spacing-5);
  gap: var(--ra-spacing-4);

  @include breakpoint(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 1074px;
    max-width: 100%;
  }
}
.lightbox-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;

  &__captions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    @include breakpoint(md) {
      width: 75%;
    }
    &--space-between {
      justify-content: space-between;
    }
  }
}
