body {
  background-color: var(--color-bg-light);
}

:root {
  --ra-spacing-1: 0.25rem; // 4px
  --ra-spacing-2: 0.5rem; // 8px
  --ra-spacing-3: 0.75rem; // 12px
  --ra-spacing-4: 1rem; // 16px
  --ra-spacing-5: 1.5rem; // 24px
  --ra-spacing-6: 2.5rem; // 40px
  --ra-spacing-7: 5rem; // 80px
  --ra-spacing-8: 7rem; // 112px

  --ra-border-radius-xl: 0.5rem;
  --ra-border-radius-circle: 62.5rem;

  --ra-focus-outline: 2px solid;

  --ra-drop-shadow: drop-shadow(0 8px 4px rgba(0, 0, 0, 0.12));
  --ra-elevation-super-light: 10px 4px 28px 0px rgba(93, 90, 87, 0.12),
    0px 2px 16px 0px rgba(0, 0, 0, 0.08);
  --ra-elevation-light: 0px 12px 36px 0px rgba(0, 0, 0, 0.12),
    0px 2px 16px 0px rgba(0, 0, 0, 0.08);
  --ra-elevation-heavy: 0px 12px 52px 0px rgba(0, 0, 0, 0.35),
    0px 8px 16px 0px rgba(0, 0, 0, 0.29);

  --settings-navbar-height: 80px;
  --settings-topbar-height: 40px;
}
