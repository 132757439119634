@import '@styles/mixins.style';

.ra-grid-item {
  grid-column: span var(--xs-cols);
  margin-block: var(--ra-spacing-4);

  @include breakpoint(sm) {
    grid-column: span var(--sm-cols);
  }

  @include breakpoint(md) {
    grid-column: span var(--md-cols);
    margin-block: var(--ra-spacing-4);
  }

  @include breakpoint(lg) {
    grid-column: span var(--lg-cols);
  }

  &--no-margin {
    margin-block: 0;
  }
}
