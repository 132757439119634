@import '@styles/mixins.style';

.ra-footer-general {
  background-color: var(--color-white);

  &__columns {
    margin-block: var(--ra-spacing-6);
    display: flex;
    flex-direction: column;
    gap: var(--ra-spacing-4);

    @include breakpoint(md) {
      margin-block: var(--ra-spacing-7);
      flex-direction: row;
      justify-content: space-between;
      gap: var(--ra-spacing-7);
    }
  }

  &__column {
    width: 100%;
    &__title {
      border-bottom: 1px solid var(--color-translucent-dark-1);
    }

    &__link {
      margin-bottom: var(--ra-spacing-4);
    }

    &__socials {
      margin-block: var(--ra-spacing-4);
      display: flex;
      gap: var(--ra-spacing-2);
    }
  }
}
