.ra-additional-services {
  margin-block: var(--ra-spacing-5);
  max-width: 100%;

  &__cards {
    padding-top: var(--ra-spacing-4);
    display: flex;
    flex-direction: column;
    gap: var(--ra-spacing-6);
  }
}
