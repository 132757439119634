.ra-blog-author {
  display: flex;
  justify-content: flex-start;
  gap: var(--ra-spacing-4);

  &__column {
    display: flex;
    flex-direction: column;
  }
}
